<template>
  <div class="hello">
    <h1>Konzert: Best of Pop(pe)</h1>
    <p>Herzliche Einladung zu unserem nächsten Konzert!</p>
    <img class="fullwidth" src="https://chantiervocal-public.s3.amazonaws.com/Flyer Best of Pop(pe).jpeg" alt="Plakat Best of Pop(pe)" />
  </div>
</template>

<script>
export default {
  name: 'FrontPage',
  data () {
    return {}
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
